import React from "react";
//import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css';
import { SocialIcon } from 'react-social-icons';
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
} from "./FooterStyles";
  
const Footer = () => {
  return (
    <Box>
      
      <Container>
        <Row>
          <Column>
            <Heading></Heading>
           
            
          </Column>
          <Column>
            <Heading>Copyright@PictureBytesMedia.com</Heading>
            
            
          </Column>
          <Column>
            <Heading></Heading>
            
            
          </Column>
          <Column style={{width: "100%"}}>
           
            <row>
              <SocialIcon network="facebook"></SocialIcon>
              <SocialIcon network="instagram"></SocialIcon>
              <SocialIcon network="twitter"></SocialIcon>
              <SocialIcon network="youtube"></SocialIcon>
              <SocialIcon network="whatsapp"></SocialIcon>
           
            </row>
          </Column>
        </Row>
      </Container>
    </Box>
  );
};
export default Footer;