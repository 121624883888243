import React, {Component} from 'react';
import {useEffect,useState } from "react";
import chapterdata from  "./chapters.json";

function handleChange(event){
  const qparam =new URLSearchParams(window.location.search);
  const chptid=qparam.get("chapterid");
  //console.log(event.target.value);
  //console.log(event.target.id);
   
  
  window.location.href=event.target.value;
  this.setState({value:event.target.value});
  event.preventDefault();
}
function Chapter () {

    const [loading,setLoading]=React.useState(true);
  const [hasError, setError] = React.useState(false);
  const [data, setData] = useState("");
 //console.log(chapterdata);
 const qparam =new URLSearchParams(window.location.search);
  const cptid= qparam.get("chapterid")-1;
  const  chptid=qparam.get("chapterid");
  const  prevchid=qparam.get("chapterid")-1;
  const  nextchid= prevchid+2;
 
  const prevcpturl='https://gita.picturebytesmedia.com/Chapter?chapterid='+prevchid ;
  const nexcpturl='https://gita.picturebytesmedia.com/Chapter?chapterid='+nextchid ;
   
    if(chptid<1){
return(<div>chapter number should be between 1 and 18</div>);
    }
    if(chptid>18){
      return(<div>chapter number should be between 1 and 18</div>);
          }
          const vcount=chapterdata.chapters[cptid].no_of_verses;

          
    return(
 
    <div>
           
      <h2  style={{ backgroundColor: "gold",textAlign:"center"}}>{chapterdata.chapters[cptid].chapter_name}:{chapterdata.chapters[cptid].chapter_title}</h2>
      <div className="row">
        <div className="col-2"></div>
        <div className="col-7">
            <h6> {chapterdata.chapters[cptid].desc}</h6>
        </div>
        <div className="col-3" style={{ backgroundColor: "",textAlign:"center",paddingTop:"100px"}}>
          <h3  style={{ color: "red",textAlign:"center"}}>వచనాలు</h3>
            {Array.from(Array(vcount), (e, i) => {
               const vid=i+1;
                const url='https://gita.picturebytesmedia.com/Verse?chapterid='+chptid+'&verseid='+vid;
                
                return <a href= {url} >
                 <button style={{ backgroundColor: "yellow",textAlign:"center", alignitems: "flexstart",width:"34px"}} variant="text">{vid}</button>  </a> 
                             
            })}
            </div>
     </div><div style={{ padding:"15px",backgroundColor: "green",textAlign:"center", alignitems: "flexstart" ,width:"250px",height:"50px"}} variant="text">
     అధ్యాయము :
            <select id="dropdown"  name="versdropdown" onChange={handleChange}>
            {Array.from(Array(18), (e, i) => {
                  const C=i+1;
            const url5='https://gita.picturebytesmedia.com/Chapter?chapterid='+C;
            const url6='https://gita.picturebytesmedia.com/Chapter?chapterid='+chptid;
                        console.log(url5);
            return <option id={i+1} selected={url5===url6} value={url5}>{i+1} {chapterdata.chapters[i].chapter_title}</option>
          
              })}
                 </select></div>
     <div className="row">
        
            </div>
            <br></br>
                     <a href= {prevcpturl}>
                     <button  style={{padding:"15px",float:"left", backgroundColor: "yellow",textAlign:"center", alignitems: "flexstart"}} variant="text">previous chapter</button> </a> 
                     <a href= {nexcpturl}>
                     <button  style={{ padding:"15px",float:"right",backgroundColor: "skyblue",textAlign:"center", alignitems: "flexstart"}} variant="text"> Next chapter</button> </a>   

    </div>

)
    
}


export default Chapter;