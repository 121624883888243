import {useEffect,useState } from "react";
import * as React from "react";
import { Container } from "./FooterStyles";
import chapterdata from  "./chapters.json";


function handleChange(event){
  const qparam =new URLSearchParams(window.location.search);
  const verid= qparam.get("verseid");
  const chptid=qparam.get("chapterid");
  console.log(event.target.value);
  console.log(event.target.id);
   
  
  window.location.href=event.target.value;
  this.setState({value:event.target.value});
  event.preventDefault();
};
function Verse(){  
  
        const[data,setData] =useState("");
        const [hasError, setError] = React.useState(false);
        const[loading,setLoading]=React.useState(true);
        const qparam =new URLSearchParams(window.location.search);
               const cptid= qparam.get("chapterid");
               const verid= qparam.get("verseid");
               if(cptid<1){
                return(<div><center><h2>అధ్యాయాలు 1 నుండి 18 వరకు ఉన్నాయి</h2>
                  </center></div>);
                    }
                    if(cptid>18){
                      return(<div><center><h2>అధ్యాయాలు 1 నుండి 18 వరకు ఉన్నాయి</h2>
                        </center></div>);
                          }
               const previd= qparam.get("verseid")-1;
               const nextvid= previd+2;
               const nextchaid= qparam.get("previd")+1;

               const url='https://gita.picturebytesmedia.com/Verse?chapterid='+cptid+'&verseid='+ previd;
               const url1='https://gita.picturebytesmedia.com/Verse?chapterid='+cptid+'&verseid='+nextvid ;
               try{
              

               useEffect(() => {
                fetch('https://api.picturebytesmedia.com/getVerse.php?chapterid='+cptid+'&verseid='+verid, {
                     crossDomain: true,
                       method: 'GET',
                       mode:'cors',
                       credentials:'same-origin',
                       headers: {'Content-Type':'application/json'}
    }).then(response =>response.json())
                .then(
                  (data) => {                    
                    setData(data)
                
                    setLoading(false)
                    console.log(data)
                  })
                
                }, []);
            console.log(data);
              }
              catch(error){
                console.log(error)
                setError(true);
                setLoading(false);
              }
              if(hasError){
                return <h4>there is some issue with the server</h4>
                     }
               if(loading) {
                return<p>Loading the response please wait</p>
               }     
               const vcount=chapterdata.chapters[cptid-1].no_of_verses;
               const chpUrl="https://gita.picturebytesmedia.com/Chapter?chapterid="+cptid; 
               const  prevchid=qparam.get("chapterid")-1;
               const  nextchid= prevchid+2;
               const prevcpturl='https://gita.picturebytesmedia.com/Verse?chapterid='+prevchid+'&verseid='+nextchaid ;
               const nexcpturl='https://gita.picturebytesmedia.com/Verse?chapterid='+nextchid+'&verseid='+nextchaid;
               if(verid<1){
                return(<div><center><h2>పద్యం సంఖ్య 1 మరియు{vcount} మధ్య ఉండాలి</h2></center>
                  {vcount}</div>);
                    }
                    if(verid>vcount){
                      return(<div><center><h2>పద్యం సంఖ్య 1 మరియు {vcount}మధ్య ఉండాలి </h2></center></div>);
                          }               
       return(
       
           <div style={{textAlign:"center"}}>
            <div className="row"> 
            <div className="col-3" >
            <a href= {prevcpturl}>
              
                     <button  style={{padding:"15px",float:"left", backgroundColor: "green",textAlign:"center", alignitems: "flexstart", width:"250px",height:"50px"}} variant="text">మునుపటి అధ్యాయం
</button> </a> 
                        
              <a href={url} ><button style={{ padding:"15px",float:"left",backgroundColor: "green",textAlign:"center",width:"250px",height:"50px"}}><h5>మునుపటి పద్యం
</h5></button></a>
<div style={{ padding:"15px",backgroundColor: "green",textAlign:"center", alignitems: "flexstart" ,width:"250px",height:"50px"}} >
     అధ్యాయము :
            <select id="dropdown"  name="versdropdown" onChange={handleChange} style={{ backgroundColor: "green"}}>
            {Array.from(Array(18), (e, i) => {
                  const C=i+1;
                  
                                    
            const url5='https://gita.picturebytesmedia.com/Chapter?chapterid='+C;
            const url6='https://gita.picturebytesmedia.com/Chapter?chapterid='+cptid;
                        console.log(url5);
            return <option style={{ backgroundColor: "green"}} id={i+1} selected={url5===url6} value={url5}>{i+1} {chapterdata.chapters[i].chapter_title}</option>
          
              })}
                 </select></div>

              </div>  
             
              <div className="col-6">
          <h2 style={{ backgroundColor: "red",textAlign:"center"}}><a href={chpUrl}> {data.chapter_name}</a></h2>
         
          <div>
            
            <h4 style={{ backgroundColor: "green",textAlign:"center"}}>పద్య సంఖ్య : {verid}</h4>
          <p style={{ backgroundColor: " ",textAlign:"center"}}>{ data.verse}</p>
   yy       
          </div>
          <h4 style={{ backgroundColor: "green",textAlign:"center"}}>ఆడియో</h4>
          <audio controls>
            <source src={data.audio_link} />
            </audio>
            <h4 style={{ backgroundColor: "green",textAlign:"center"}}>ఉద్దేశ్యం
:-తాత్పర్యం</h4>
           <p> {Array.isArray(data.purport)?data.purport.map((item)=><p>{item}</p>):null}</p>
          
           </div>
           <div className="col-3" >
        
           

           <a href= {nexcpturl}>
                     <button  style={{ padding:"15px",backgroundColor: "green",textAlign:"center", alignitems: "flexstart" ,width:"250px",height:"50px"}} variant="text"> తదుపరి అధ్యాయం
</button> </a>
           <a href={url1} ><button style={{padding:"15px", backgroundColor: "green",textAlign:"center", width:"250px",height:"50px"}}><h5>తదుపరి పద్యం
</h5>
</button></a><div style={{ padding:"15px",backgroundColor: "green",textAlign:"center", alignitems: "flexstart" ,width:"250px",height:"50px"}} variant="text">
పద్యం :
            <select id="dropdown"  name="versdropdown" onChange={handleChange}>
            {Array.from(Array(vcount), (e, i) => {
            const url5="https://gita.picturebytesmedia.com/Verse?chapterid="+cptid+"&verseid="+(i+1);
            const url6="https://gita.picturebytesmedia.com/Verse?chapterid="+cptid+"&verseid="+(verid);
            console.log(url6);
            return <option id={i+1} selected={url5===url6} value={url5}>{i+1}</option>
          
              })}
                 </select></div>
           </div>
              </div> 
           </div> 
           

       );
           
       
    
       }
    
       


 

export default Verse;
//<h6> {chapterdata.chapters[cptid].desc}</h6>
         
            
/*{Array.from(Array(vcount), (e, i) => {
   const vid=i+1;
    const url='https://gita.picturebytesmedia/App?chapterid='+chptid+'&verseid='+vid;
    return <a href= {url} >
     <button variant="text">{vid}</button>  </a> 
})}*/
