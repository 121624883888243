import React from "react";
import chapterdata from  "./chapters.json";
import Chapter from "./Chapter";
function About() {
    
return(
 
<div>
   <p> This is About Page and it will have the information about Bhagavath Gita and some images releted to Bhagavath Gita. </p>
</div>
)

}
export  default About;