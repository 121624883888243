 //import {useEffect,useState } from "react";
 import React from "react";
 import "./App.css";
 //import { withRouter } from "react-router-dom";
 import Header from './Pages/Header'
 import Footer from './Pages/Footer'
 //import  {Component} from 'react'

import Chapter from './Pages/Chapter';
import Verse from './Pages/Verse';
import Index from './Pages/Index';
import About from './Pages/About';
import Contact from './Pages/Contact';
import chapterdata from  './Pages/chapters.json';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'  ;
import 'bootstrap/dist/css/bootstrap.min.css';
 




  
function App() {
 
     console.log(chapterdata);
  return (    
    <div >
      <Header/>
      
      <div>
     
       <Router>
        <Routes>
        

        <Route path='/Index' element={<Index/>} />
        <Route path='/About' element={<About/>} />
        <Route path='/Contact' element={<Contact/>} />
        <Route path='/Chapter' element={<Chapter/>} />
        <Route path='/Verse' element={<Verse/>} />

    </Routes>
    </Router>
    </div>
            <div>
              <h1  style={{ color: "blue",textAlign:"center",paddingTop:"40px"}}></h1>
              
            </div>
        <div><Footer/></div>
        </div>
        
  );
}

  
export default App;
