import React from 'react';
import logo from './pbmlogo.png';
import { hover } from '@testing-library/user-event/dist/hover';
import chapterdata from './chapters.json';
function Header (){

  const [searchedArray, setSearchedArray] = React.useState(chapterdata.chapters);
  const [searchString, setSearchString] = React.useState("");

  React.useEffect(() => {
    if(searchString.length === 0){
      setSearchedArray(chapterdata.chapters)
    }
   else 
   {
      const searchedObjects = []
      chapterdata.chapters.forEach((singleChapterObject, i) => {
        Object.values(singleChapterObject).every((onlyValues, valIndex) => {
          if(onlyValues.toLowerCase().includes(searchString.toLowerCase())){
            searchedObjects.push(singleChapterObject)
            return;
          }
        })
      })
      setSearchedArray(searchedObjects) 
    }
  }, [searchString])

  

  
  

    return (
      
       
            <nav  class="navbar navbar-expand-sm bg-info navbar-dark" >
        <img src={logo} height="60" alt="img" />
       
     
      
      <ul class="navbar-nav ms-auto ">
        <li class="nav-item active">
          <a class="nav-link" style={{color:"black",hover:"white"}} href="Index">మొదటి పేజీ
</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" style={{color:"black"}}href="About">వెబ్‌పేజీ గురించి
</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" style={{color:"black"}}href="Contact">సంప్రదించండి</a>
        </li>
        
      </ul>
    </nav>
    
     )
  }

export default Header;
/*<SocialIcon url="https://linkedin.com" />
            <SocialIcon url=" https://www.facebook.com/home" />
            <SocialIcon url="https://twitter.com" /> 
            <SocialIcon url="https://youtube.com" />
            */