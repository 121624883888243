import React from "react";
import chapterdata from  "./chapters.json";

import hcbgImage from "./home-bg.jpg";
import DropdownButton from "react-bootstrap/esm/DropdownButton";

function Index() {
    
return(
                    
                    
<div>
    
    
    
      <div
        class="bg_image"
        style={{
          backgroundImage: 'url('+hcbgImage+')',
          backgroundSize: "cover",
          height: "100vh",
          color: "#f5f5f5"
        }}
      >
        <h1 style={{ color: "red",textAlign:"center",paddingTop:"40px"}}>భగవద్గీత</h1> 
       
      </div>
      <div className="row">
        <h4 style={{ color: "blue",textAlign:"center",paddingTop:"20px",paddingBottom:"20px"}}>అధ్యాయాలు</h4>
      </div>
      <div className="row">
      <div className="col-3"/>
        
         <div className="col-3" >

    
    {Array.from(Array(18), (e, i) => {
               const C=i+1;
                const url='https://gita.picturebytesmedia.com/Chapter?chapterid='+C;
                //const k=chapterdata.chapters[i].chapter_name;
               const j= i %2 ;
                 return  j ===0 ? <a href= {url} >
                 <button  style={{backgroundColor:"orange", width: "400px",textAlign:"center"}} variant="text">{chapterdata.chapters[i].chapter_name}:-<br/>{chapterdata.chapters[i].chapter_title}</button> </a>: "" 
               
                   
            })
          }
            </div>
            <div className="col-3">
            {Array.from(Array(18), (e, i) => {
               const C=i+1;
                const url='https://gita.picturebytesmedia.com/Chapter?chapterid='+C;
                //const k=chapterdata.chapters[i].chapter_name;
               const j= i %2 ;
                 return  j ===0 ? "": <a href= {url} >
                <button  style={{ backgroundColor:"orange",width: "300px",textAlign:"center"}} variant="text">{chapterdata.chapters[i].chapter_name}:-<br/>{chapterdata.chapters[i].chapter_title}</button> </a> 
               
                   
            })
          }
              
            </div>
            <div className="col-3"/>
            </div>
            <div></div>
            <div className="row">
              <div className="col-4"></div>
            </div>
</div>
)

}
export  default Index;
/*(<Box>
                        
    <Container>
        <Row>
                <Column>
                    <Heading>
                    
                        
                    </Heading>
                
                </Column>
        
        
        </Row>
    </Container>
</Box>)*/